@import "mixins";
@import "variables/colors";

.patients-dashboard {
  .table.patients-table {
    margin-top: 3rem;
    margin-bottom: 3rem;
    thead {
      background-color: $table-head-grey;
    }

    th,
    td {
      border: 1px solid $table-cell-border;
    }

    td {
      padding: 15px 1.4rem;
    }
    .tr--clickable:hover {
      background-color: $light_blue;
    }

    .tr--selected {
      background-color: $tr-hover;
      &:hover {
        background-color: $tr-hover;
      }
    }
  }
  .button-container {
    float: right;
    margin-bottom: 0;
    .button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .positive,
  .negative,
  .pending {
    font-weight: bold;
  }
  .positive {
    color: $positive-red;
  }
  .negative {
    color: $negative-green;
  }
  .pending {
    color: $text_black;
  }
  .full-top-bar {
    margin-bottom: 20px;
  }
  .bottom-bar {
    margin-top: 20px;
  }
}
