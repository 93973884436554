@import "variables/colors";
@import "mixins";

.notification-wtih-icon {
  display: flex;
  margin: 40px 0;
  font-size: 1rem;

  .icon svg {
    @include size(48px);
  }

  .col {
    justify-content: center;
  }

  &.informative .icon svg {
    fill: $link_blue;
  }

  &.danger .icon svg {
    fill: $risk_red;
  }

  &.warning .icon svg {
    fill: $risk_orange;
  }
}
