@import "mixins";
@import "variables/colors";

form {
  .button-container {
    float: right;
    margin-bottom: 20px;
    margin-top: -45px;
    .button {
      font-size: 1rem;
      &--cancel-text {
        font-size: 12px;
      }
    }
  }
}

.qr-codes {
  &__code-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;

    .qr-code__image {
      margin-right: 12px;
    }
  }

  &__name {
    min-width: 100px;
  }

  &__actions-column {
    min-width: 100px;
  }

  &__description {
    line-height: normal;
  }
}

.qr-details-header {
  padding: 0 1rem 2rem 0;
  .text {
    line-height: 2.5rem;
  }
}

.table-container {
  width: 100%;
  display: flex;
  .table.access-profiles-table {
    width: 100%;
    height: 100%;

    thead {
      background-color: $table-head-grey;
    }

    th,
    td {
      border: 1px solid $table-cell-border;
      &.tr--sorted-desc {
        padding-top: 6px;
        border-bottom: 3px solid $table-sorted;
      }

      &.tr--sorted-asc {
        padding-bottom: 6px;
        border-top: 3px solid $table-sorted;
      }
    }
  }
  .phone-image-1 {
    margin: 40px 25px 14px 45px;
    width: rem(218px);
    height: rem(336px);
  }
}

.test-container {
  background-color: $light_blue;
  margin: -15px 35px 14px;
  padding-left: 254px;
}
