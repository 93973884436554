@import "variables/colors";

.organization-contacts-table {
  margin-top: 2rem;
  margin-bottom: 4rem;
  .channels {
    width: 150px;
    background-color: $table-head-grey;
  }

  td {
    height: 32px;
    vertical-align: middle;
  }

  thead {
    display: none;
  }
}
