@import "variables/colors";

.patient-details {
  .devider {
    padding: 0;
    margin: 20px 20px 0px;
  }
  .information-container {
    margin: 0 20px 25px 20px;
    background-color: $light-blue;
    padding-bottom: 100px;

    .qr-code-contact-container {
      display: flex;
    }
  }

  .patient-details__qr-code {
    text-align: center;
  }
  .patient-details__spinner {
    width: 242px;
  }
  > .spinner {
    margin-bottom: 30px;
  }
}
