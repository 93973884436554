@import "variables/colors";

.data-retention {
  margin: 0 35px 14px;
  .title-container {
    display: flex;
    flex: 3 2 2;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;

    .page-title {
      margin-left: 0;
    }
  }
  
  .table-container {
    display: block;
    background-color: $light_blue;
    border-top: 2px solid transparentize($color: $header-blue, $amount: 0.5);
    .table-data-retention {
      margin: 0;
      padding: 57px 25px 20px;
     
      thead, .bottom-details {
        display: none;
      }
      td {
        border: 1px solid $table-cell-border;
        padding: 1.2rem 0.4rem;
        line-height: 2.5;
      }
      td:first-child {
        border-left: none;
        padding-left: 20px;
        width: 40%;
      }
      td:last-child {
        border-right: none;
      }
    }
    .default-note {
      font-size: 12px;
      font-style: italic;
      padding-left: 45px;
      padding-bottom: 40px;
    }
  }
  .input-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 50px;
    .input-name {
      font-weight: bold;
      display: flex;
      align-items: center;
      margin-right: 50px;
    }
    .text-value {
      width: 90px;
    }
    .input {
      border-radius: 0;
      border: 1px solid $table-cell-border;
      padding: 7px;
    }
  }
}