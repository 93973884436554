@import "variables/colors";

.header-add-button {
  float: right;
  margin: 25px 25px 0 0;
}

.actions-column {
  width: 110px;
}
.event-admin-column {
  width: 100px;
}

.tp-admin-column {
  width: 150px;
}

.enterprise-admin-column {
  width: 130px;
}

.roles-column {
  min-width: 180px;
}

.event-admin-column,
.tp-admin-column,
.enterprise-admin-column {
  span {
    display: flex;
    justify-content: center;
  }
}

.name-column {
  max-width: 100px;
  overflow-wrap: break-word;
}

.devider {
  margin: 75px 20px 20px;
  border-top: 2px solid $header-blue;
  opacity: 0.6;
}
