@import "variables/colors";
@import "variables/configs";

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
}

@f-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Italic.ttf") format("ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("./fonts/AvenirNext-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

#root,
body,
html {
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  display: flex;
  flex-direction: column;
}

input,
textarea,
select,
button {
  font-family: OpenSans, Arial, sans-serif;
}

html {
  font-size: $base-font-size;
  color: $text_black;

  * {
    padding: 0;
    margin: 0;
  }
}

a {
  text-decoration: none;
}

h1 {
  font-size: 1.72rem;
  line-height: normal;
  font-weight: normal;
}

@media print {
  html {
    font-size: 14px;
  }
}
