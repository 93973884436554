@import "mixins";
@import "variables/colors";

.test-requirements {
  .button {
    font-size: 1rem;
  }
  .header-add-button {
    margin-right: 45px;
  }

  .title-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .page-title {
      margin-top: 37px;
      margin-bottom: 40px;
      margin-left: 32px;
    }
  }

  .confirm {
    button {
      font-size: 16px;
    }
  }

  .qr-actions {
    display: flex;
    justify-content: center;
  }
  &-row-container {
    padding: 0 45px 0 18px;
  }
}

.test-requirements-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .form-group {
    width: 45%;
  }
  .select-option__control {
    width: 100%;
  }

  .days-hours-picker {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 48px;
    width: 100%;
    margin-top: 2px;

    .day-picker-container,
    .hour-picker-container {
      display: flex;
      flex-direction: column;
      .label {
        min-width: unset;
      }
    }
    .day-picker-container {
      flex-basis: 50%;
    }
    .hour-picker-container {
      flex-basis: 40%;
    }
  }

  .day-time-input,
  .hours-time-input {
    padding: 0.5rem;
    height: 2.2rem;
    min-height: 2.2rem;
  }

  .day-time-input {
    width: 175px;
  }

  .hours-time-input {
    width: 100px;
  }
  .delete-button {
    margin-top: 10px;
  }
}

.qrCode-title {
  background-color: rgba(227, 242, 255, 0.3);
  border-radius: 32.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 186px;
  margin: 0 auto;
  padding: 1rem 3rem;
}

.qr-summary {
  font-size: rem(11px);
  padding: 0 45px 14px;
  flex-grow: 1;
  li {
    list-style-position: inside;
  }

  .page-title {
    margin-left: 0px;
  }
  .table {
    margin: 0;
    margin-left: -1rem;
    margin-bottom: 10px;
  }
}

.accordion-item {
  width: 25rem;
}

.list_custom_bullet {
  width: 7px;
  height: 7px;
  display: inline-block;
  margin-right: 5px;
}

.and-or {
  margin-bottom: 1rem;
  margin-left: 10%;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  label {
    display: inline-block;
    margin-left: 1rem;
    color: $header-blue;
    background-color: white;
    border: 1px solid $link-blue;
    padding: 0.5rem;
    width: 70px;
    text-align: center;
    border-radius: 4px;
    text-transform: uppercase;
    &.selected {
      background-color: $header-blue;
      color: white;
    }
  }
}
