$risk_green: #7ed321;
$risk_yellow: #f8e71c;
$risk_orange: #f27314;
$risk_red: #cc0033;

$risk_arrow_up: #b06a6a;
$risk_arrow_down: #a9d3ce;

$text_black: #454545;
$text_gray: #8d8d8d;
$text_light_gray: #757575;
$link_blue: #0465cc;
$svg_hover_blue: #0066cc;

$gray: #efefef;
$white: #ffffff;
$black: #000000;
$light_gray: #818181;

$logo_blue: #0078c1;
$background-blue: #f3f6fb;
$input-border-color: #646464;
$primary-blue: #0066cc;
$header-blue: #0066cc;
$link-blue: #228af2;
$gray-disabled: #8c9395;
$secondary-gray: #71787a;

$table_cell_pale: #e3f2ff4d;
$table_cell_gray: #f6f6f6;
$table_row_selected: #e3f2ff;
$table-head-grey: #e7e7e7;
$table-head-blue: #c2def5;
$table-cell-border: #c9c9c9;
$table-row-border: #b6b6b6;
$tr-clickable: #fdfdfd;
$tr-hover: #d5eafc;
$table-sorted: #1372d6;

$light-theme-alert-red: #ef4242;
$light-theme-alert-green: #55cc22;

$black-disabled: #00000061;
$black-medium-emphasis: #00000099;
$light_blue: #e3f2ff;
$background-grey: #e4e4e4;
$border-dark-grey: #2e2e2e;
$border-light-grey: #bebebe;
$icon-active: #0059b1;

$inactive_client_grey: #d1d1d1;
$new_client_yellow: #fff6c6;

$outcome_0: #c71715;
$outcome_1: #e02727;
$outcome_2: #f18523;
$outcome_3: #f1c421;
$outcome_4: #fee500;
$outcome_5: #c6e873;
$outcome_6: #88e042;
$outcome_7: #26c024;

$positive-red: #b21010;
$negative-green: #0f722e;
