@import "variables/colors";
.data-export {
  &__form-container {
    display: block;

    .button-container {
      margin-bottom: 38px;
      margin-top: 0;

      .button {
        margin-top: 0;
      }
      .button.header-add-button {
        font-size: 16px;
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    margin-left: 45px;
    margin-top: 20px;

    .form-group {
      position: relative;
      .error {
        position: absolute;
        top: 100%;
      }
    }

    .dates-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 600px;
      margin-right: 65px;

      .separator {
        margin-right: 15px;
        margin-left: 15px;
      }

      .calendar {
        position: absolute;
        right: 5%;
        top: 50%;
        z-index: 1;
      }

      .date-picker {
        background: #ffffff00;
      }
    }

    .select-option__control {
      min-width: 400px;
    }
    .date-picker,
    .date-picker--error {
      width: 95%;
      padding: 0 0.5rem;
      height: 46px;
      border-radius: 4px;
      border: 1px solid $text_black;
      font-size: 16px;
      text-transform: uppercase;
    }
    .date-picker--error {
      border: 1px solid $light-theme-alert-red;
      z-index: 0;
    }
  }
  &__table-container {
    padding: 4rem 25px 1rem 45px;

    .table.data-export__table {
      width: 100%;
      height: 100%;
      margin-left: 0;

      .icon.readyToDownload,
      .icon.errorAlert {
        display: flex;
        margin-left: 20px;
        svg {
          align-self: center;
        }
      }
      .button.button--row-action {
        vertical-align: middle;
        margin-left: 0;
        .icon {
          margin-left: 0;
        }
      }
      thead {
        background-color: $table-head-grey;
        th {
          &:first-child {
            .header_filterGroup {
              >div {
                padding-top: 3px;
              }
            } 
          }
          &:last-child {
            .header_filterGroup {
              >div {
                padding-top: 3px;
              }
            }
          }
        }
      }

      .header_group {
        .header-content {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }

      .header_filterGroup {
        padding-top: 6px;
        .header-title {
          margin-bottom: 0;
          align-items: center;
          .sortAsc, .sortDesc {
            height: 10px;
            margin-top: 2px;
          }
        }
      }

      th,
      td {
        border: 1px solid $table-cell-border;
        &.tr--sorted-desc {
          padding-top: 6px;
          border-bottom: 3px solid $table-sorted;
        }

        &.tr--sorted-asc {
          padding-bottom: 6px;
          border-top: 3px solid $table-sorted;
        }
      }

      td {
        line-height: 2.2;
      }

      .column-date-range {
        width: 200px;
      }
      .column-job-started {
        width: 120px;
      }
      .status-container {
        min-width: 200px;
        display: flex;
        justify-content: space-between;
        p {
          margin-bottom: 0;
        }
      }
      .column-actions {
        width: 100px;
      }
    }
  }
}
