@import "variables/font";
@import "variables/colors";
@import "variables/configs";

.qr-page_container {
  display: flex;
  justify-content: center;
  align-items: center;
  .qr-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .title {
    font-size: 18px;
    padding-top: 18px;
    &--test-recipient,
    &--test-results {
      color: $header-blue;
      text-transform: uppercase;
      margin-bottom: 3rem;
    }
    &--profile {
      color: $link-blue;
    }
  }
  .data-container--patient,
  .data-container--test-results {
    max-width: 400px;
    margin-top: 3rem;
    margin-left: 80px;

    .data-container_row {
      display: flex;
      align-content: center;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      line-height: 22px;
      margin-bottom: 20px;

      .data-key {
        font-size: 16px;
        width: 200px;
        flex: 0 1 200px;
      }
      .data-value {
        font-size: 16px;
        justify-self: self-start;
        flex: 0 1 400px;
        word-break: break-all;
        &.name {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
  .data-container--profiles {
    text-align: center;
    margin-top: 1rem;
    h2 {
      font-size: 16px;
      margin-bottom: 1rem;
    }
    p {
      font-size: 16px;
      margin-bottom: 1rem;
    }
    .link {
      font-size: 14px;
      color: $link-blue;
      margin-bottom: 1rem;
    }
  }
}

.qrCode-image {
  margin: 0 auto;
  display: block;
  padding: 3rem 2rem 0.5rem 2rem;
}

@media print {
  .qr-page_container {
    padding: 0;
    max-width: 100%;
  }
}
