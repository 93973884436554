@import "variables/colors";

.dates-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form-group {
    width: 100%;
  }

  .date-picker,
  .date-picker--error {
    padding: 0px 0px 0px 14px;
    height: 46px;
    border-radius: 4px;
    border: 1px solid $text_black;
    font-size: 16px;
    text-transform: uppercase;
    width: calc(100% - 17px);
  }
  .date-picker--error {
    border: 1px solid $light-theme-alert-red;
    z-index: 0;
  }

  .react-datepicker__time-container {
    width: 84px;
  }

  .calendar {
    position: absolute;
    right: 5%;
    top: 33px;
    z-index: 1;
  }
}
