@import "variables/colors";

.table.adminUsers {
  td,
  th {
    border: 1px solid $table-cell-border;
  }

  thead {
    background-color: $table-head-grey;
  }
}
