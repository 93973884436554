@import "mixins";
@import "variables/colors";

.search-table {
  .table {
    margin: 0;
    .full-top-bar {
      margin-bottom: 29px;
    }

    thead {
      background-color: $table-head-grey;
    }

    th,
    td {
      border: 1px solid $table-cell-border;
    }

    td {
      padding: 15px 1.4rem;
    }
    .tr--clickable:hover {
      background-color: $light_blue;
    }

    .tr--selected {
      background-color: $tr-hover;
      &:hover {
        background-color: $tr-hover;
      }
    }

    .sub-header {
      color: $text_gray;
    }

    .header-title {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-content: center;
    }

    .sorting-icons {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      .icon {
        align-self: center;
        svg {
          width: 10px;
          height: 10px;
          fill: $table-cell-border;
        }
      }

      .active.icon {
        svg path {
          fill: $primary-blue;
        }
      }
    }
  }
}
