@import "mixins";
@import "variables/colors";

.qr-code-details {
  margin: 0 35px 14px;
  border-top: 2px solid transparentize($color: $header-blue, $amount: 0.5);

  .button {
    font-size: 1rem;
  }

  .confirm {
    button {
      font-size: 16px;
    }
  }

  .contact-information {
    display: flex;
    background-color: $light_blue;

    .qr-actions {
      display: flex;
      justify-content: center;
    }
  }
}

.qrCode-title {
  background-color: rgba(227, 242, 255, 0.3);
  border-radius: 32.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 186px;
  margin: 0 auto;
  padding: 1rem 3rem;
}

.qr-summary {
  font-size: rem(11px);
  padding: 0 45px 14px;
  flex-grow: 1;
  li {
    list-style-position: inside;
  }

  .page-title {
    margin-left: 0px;
  }
  .table {
    margin: 0;
    margin-left: -1rem;
    margin-bottom: 10px;
  }
}

.accordion-item {
  width: 25rem;
}

.list_custom_bullet {
  width: 7px;
  height: 7px;
  display: inline-block;
  margin-right: 5px;
}
