@import "variables/font";
@import "variables/colors";

.text {
  line-height: 1.14;
}

.h2 {
  font-size: 24px;
  font-weight: $bold;
  margin: 0;
}

.h4 {
  font-size: 18px;
  font-weight: $bold;
  margin: 0;
}

.p {
  font-size: 1rem;
}

.small {
  font-size: 1rem;
}

.bold {
  font-weight: $bold;
}

.extraBold {
  font-weight: $extrabold;
}

.gray {
  color: $text_gray;
}
