@import "mixins";
@import "variables/colors";

textarea {
  vertical-align: middle;
  text-align: justify;
}

textarea.table-cell-input {
  box-sizing: border-box;
  font-size: rem(14px);
  padding: 0.25rem;
  border: 0;
  line-height: 1.25rem;
  width: 100%;
  resize: none;
  outline: 1px solid $border-light-grey;
  margin-top: -1px;
  margin-left: -3px;
}

.day-hours-input {
  width: 100px;
  padding: 0.5rem;
  border: 1px solid $border-light-grey;
}

.date-picker {
  padding: 0.5rem;
  border: 1px solid $border-light-grey;
}

.cell-badge {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  flex-direction: column;
  overflow-wrap: anywhere;
  span {
    display: inline-block;
    position: absolute;
    right: rem(5px);
    top: rem(5px);
    &.badge {
      background-color: $primary-blue;
      border-radius: 50%;
      color: $white;
      min-width: rem(13px);
      min-height: rem(13px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(11px);
    }
  }
  textarea,
  p {
    padding-right: rem(18px);
  }
}
