@import "variables/colors";

.edit-user-form {
  .label {
    display: inline-block;
  }

  .role-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      color: $svg_hover_blue;
      font-size: 14px;
      background: none;
      border: none;
      font-weight: bold;
    }
  }

  .form-group {
    max-width: 480px;
    .error:not(:first-of-type) {
      position: relative;
    }
  }
}
