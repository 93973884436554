@import "variables/colors";

.cohort-survey-container {
  margin: 100px 35px;
  .page-title {
    margin-bottom: 28px;
    margin-left: 0;
  }
  .survey-button-container {
    margin-top: -38px;
  }
  .data-export__table-container {
    padding: 4rem 25px 1rem;
    background-color: $light-blue;
    border-top: 2px solid transparentize($color: $header-blue, $amount: 0.5);
  }
  table {
    thead {
      background-color: $table-head-blue;
    }
    td {
      border: 1px solid $table-cell-border; 
    }
    .answer-container {
      .code {
        border-right: 1px solid $table-cell-border;
      }
    }
  }
}
