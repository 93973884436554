@import "variables/colors";

.home-test-type-title {
  .page-title {
    text-transform: capitalize;
  }
}

.home-test-type {
  background-color: $light_blue;
  padding: 3rem 0.5rem;

  table {
    td,
    th {
      border: 1px solid $table-cell-border;
    }
    td {
      height: 50px;
    }
    tbody {
      tr {
        td:last-of-type {
          textarea {
            height: 6.8rem;
          }
        }
      }
    }
  }
}
