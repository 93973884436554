.edit-data-element-form {
  .wrapper {
    max-height: 90%;
    overflow-y: auto;
    max-width: 500px;

    .form-group {
      .error {
        position: relative;
        top: 100%;
      }
    }
  }

  span {
    position: relative;
  }
  &-button-container {
    position: absolute;
    right: 0;
    button {
      border: none;
      background: none;
      cursor: pointer;
      margin: 2px;
    }
  }
  .hint-textarea {
    height: 47px;
    margin-top: 0;
    border-radius: 4px;
    font-size: 16px;
  }
  .select-answers {
    span {
      display: none;
    }
  }
}
