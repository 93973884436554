@import "variables/font";
@import "variables/colors";

.about-container {
  padding: 0 45px;
  font-size: 1rem;
  line-height: 1.43;

  .block {
    margin-bottom: 25px;
    max-width: 620px;
    white-space: pre-line;

    & > span {
      display: inline-block;
    }

    &.title {
      font-size: 1.714rem;
      font-weight: $normal;
      margin-top: 45px;
      margin-bottom: 5px;
    }
    &.version {
      font-weight: $bold;
      font-size: 1rem;
    }

    .icon {
      vertical-align: middle;
      margin-right: 28px;
    }

    &.manufacturer {
      .icon {
        vertical-align: top;
      }
      span:nth-of-type(2n) {
        padding-left: 4px;
      }
    }

    &.warning {
      font-weight: $bold;
      margin-left: 4px;
    }
    &--quantity {
      border: 3px solid $text_black;
      border-radius: 2px;
      color: $text_black;
      display: inline-block;
      padding: 7px 20px 5px 5px;
      font-weight: bold;
      font-family: "Times New Roman", Times, serif;
    }
    &.content {
      margin-left: 70px;
    }
    .wip {
      padding-left: 8px;
    }
  }
}
