@import "variables/colors";
@import "mixins";

.modal {
  @include size(100%);
  background: transparentize($black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  overflow-y: scroll;

  > .wrapper {
    min-width: 315px;
    max-height: 90%;
    overflow-y: auto;

    .form-group {
      position: relative;
      margin-bottom: 28px;
      margin-top: 10px;

      .error {
        position: absolute;
        top: 100%;
      }
    }
  }

  &-actions {
    text-align: center;
  }

  &.confirm {
    > .wrapper {
      width: 335px;
      padding: 50px 25px 0;
      text-align: center;
      overflow-wrap: break-word;

      h4 {
        margin-bottom: 30px;
      }
    }
  }
  &.add {
    > .wrapper {
      .button {
        font-size: 1rem;
        &--cancel-text {
          font-size: 12px;
        }
      }
    }
  }
}
