@import "variables/colors";

.home-test-additional {
  background-color: $light_blue;
  padding: 3rem 45px 3rem 0.7rem;
  display: flex;

  table {
    td,
    th {
      border: 1px solid $table-cell-border;
    }
    td {
      padding: 14px 7px 14px 21px;
    }
  }

  img {
    width: 223px;
    height: 535px;
  }

  .table-container {
    display: block;
    margin-left: 35px;

    textarea {
      height: 6.8rem;
    }
    p {
      height: 100%;
      white-space: pre-line;
    }

    .title-container {
      display: flex;
      justify-content: space-between;
      height: 100%;
      p {
        margin: 0;
      }
      span.badge {
        display: inline-block;
        position: static;
        background-color: $header-blue;
        border-radius: 50%;
        color: white;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        line-height: 11px;
        min-width: 15px;
        margin-left: 5px;
      }
    }
  }
}