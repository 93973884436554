.container {
  display: flex;
  align-items: inherit;
  flex-direction: inherit;
}

@media only screen and (min-width: 1200px) {
  .container {
    width: calc(100vw - 90px);
    margin-left: auto;
    margin-right: auto;
  }
}
