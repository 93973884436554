@import "variables/colors";

.survey__table {
  width: 100%;
  height: 100%;
  margin-left: 0;
  margin-bottom: 5rem;

  .button.button--row-action {
    vertical-align: middle;
    margin-left: 0;
    .icon {
      margin-left: 0;
    }
  }
  thead {
    background-color: $table-head-grey;
  }

  .header_group {
    .header-with-icon { 
    display: flex;
    align-items: center;
      .icon {
        margin-left: 10px;
      }
    }
  }

  tbody > tr {
    vertical-align: top;
  }

  th,
  td {
    border: 1px solid $table-cell-border;
  }
  td {
    line-height: 2.2;
  }
  td.multiline {
    white-space: pre-line;
  }

  .column-required {
    width: 65px;
    p {
      text-transform: capitalize;
    }
  }

  .column-type {
    width: 10%;
  }

  .column-label {
   width: 15%;
  }

  .column-hint {
    width: 10%;
  }

  .column-fieldName {
    width: 10%
  }

  .column-answers {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .column-actions {
    width: 80px;
  }

  .checkbox {
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }
  .header-container {
    display: flex;
    height: 100%;
    .header-code, .header-with-icon {
      width: 100%;
      padding: 5px 20px;
    }
    .header-content {
      width: 100%;
      &:first-child {
        border-right: 1px solid $gray;
      }
    }
    .header-code {
      display: flex;
      align-items: flex-start;
      &-content {
        min-height: 32px;
        display: flex;
        align-items: center
      }
    }
    .header-label-content {
      display: flex;
      align-items: flex-start;
      .header-with-icon {
        height: unset;
        min-height: 32px;
      }
    }
  }
  
  .answer-container {
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    flex: 0 1 auto;
    height: 100%;
    .code, .label-text{
      width: 100%;
      padding: 5px 20px;
    }
    .code {
      border-right: 1px solid $gray;
    }
  }
}
.survey-button-container {
  display: flex;
  float: right;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 5px 1.4rem;
  .button_text {
    font-size: 14px;
  }
  
}