@import "variables/colors";

.patient-consent {
  width: 386px;

  &-content {
    padding: 10px 0;
    line-height: 24px;
  }
}
