@import "variables/colors";
@import "mixins";

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  &.spinner-circle {
    @include size(48px);
    border: 2px rgba($logo_blue, 0.25) solid;
    border-top: 2px rgba($logo_blue, 1) solid;
    border-radius: 50%;
    animation: spCircRot 0.6s infinite linear;
  }

  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}
