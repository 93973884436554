@use "sass:math";

@import "variables/configs";

@mixin media($minOrMaxWidth, $breakpoint) {
  @media screen and ($minOrMaxWidth: $breakpoint) {
    @content;
  }
}

@mixin size($size) {
  width: $size;
  height: $size;
}

@function rem($size) {
  @return math.div($size, $base-font-size) * 1rem;
}
