@import "variables/colors";

.radio-button {
  width: 16px;
  height: 16px;
  border-radius: 10px;
  border: 1px solid $text_black;
  position: relative;
  display: inline-block;

  &--selected:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    top: 3px;
    left: 3px;
    border-radius: 10px;
    background-color: $link_blue;
  }
}

.table table td.view-users-column {
  width: 100px;
  text-align: center;
}

.actions-column {
  min-width: 110px;
}

.table table td.institutionName-column {
  max-width: 800px;
  overflow-wrap: break-word;
}
