@import "variables/colors";
@import "variables/configs";

.form-field-title {
  font-size: 1.42857rem;
  margin: 0 0 20px;
  text-transform: uppercase;
  color: $link-blue;
  font-weight: normal;

  &.no-margin {
    margin-bottom: 0;
  }
}
