.form-checkbox-conatainer {
  display: flex;
  align-items: center;
  line-height: 18px;

  input[type="checkbox"] {
    width: 18px;
    height: 18px;
  }
  .combo-label {
    margin-left: 10px;
    font-size: 14px;
  }
}