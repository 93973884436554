@import "mixins";
@import "variables/colors";

.button-container {
  float: right;
  margin-bottom: 20px;

  .button {
    font-size: 1rem;
    &--cancel-text {
      font-size: 12px;
    }
  }
}

.profiles {
  > .spinner {
    width: 100%;
  }
}

.table-container {
  .table.qr-codes-table {
    width: 100%;
    height: 100%;

    thead {
      background-color: $table-head-grey;
    }

    th,
    td {
      border: 1px solid $table-cell-border;
      &.tr--sorted-desc {
        padding-top: 6px;
        border-bottom: 3px solid $table-sorted;
      }

      &.tr--sorted-asc {
        padding-bottom: 6px;
        border-top: 3px solid $table-sorted;
      }
    }
    .check{
      svg {
        width: 100%;
      }
    }
  }
}
