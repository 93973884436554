@import "variables/colors";

.icon {
  line-height: 1;

  &.logo svg {
    width: 70px;

    path {
      fill: $white;
      fill-rule: nonzero;
      stroke: none;
    }
  }

  &.up svg {
    fill: $risk_arrow_up;
  }

  &.down svg {
    fill: $risk_arrow_down;
  }

  &.chevron {
    &.left svg {
      transform: rotate(90deg);
    }
    &.right svg {
      transform: rotate(-90deg);
    }
  }

  &.firstPage,
  &.lastPage {
    svg {
      margin-bottom: -4px;
    }
  }

  &.houseEmpty polygon {
    fill: none;
    stroke-width: 4;
    stroke: $text_black;
  }

  &.LOT polygon {
    fill: none;
    stroke-width: 2;
    stroke: $text_black;
  }

  &.phone path {
    fill: none;
    stroke: $black;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }

  &.cross {
    &.plus svg {
      transform: rotate(45deg);
    }
  }

  .filter {
    svg path {
      fill: $text_light_gray;
    }
  }
  &.edit:hover,
  &.download:hover,
  &.refresh:hover,
  &.upload:hover,
  &.reset:hover,
  &.trash:hover,
  &.print:hover,
  &.duplicate:hover {
    svg path {
      fill: $svg_hover_blue;
    }
  }

  &.patientStatus svg path {
    fill: $svg_hover_blue;
  }
}
