.confirm-popup-buttons {
  margin: 60px -25px 0;
  display: flex;
  font-size: 16px;
}

.confirm {
  white-space: pre-line;
  line-height: 20px;
}
