.decision-validity {
  padding-bottom: 60px;

  .page-title {
    margin-left: 32px;
    text-transform: none;
  }

  &--input-field {
    padding-left: 18px;
    padding-top: 20px;

    .form-group {
      position: relative;

      .error {
        position: absolute;
        top: 100%;
      }
    }

    .day-hours-time-input {
      width: 175px;
      padding: 0.5rem;
      height: 2.2rem;
    }
  }
}
