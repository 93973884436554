@import "variables/configs";

.input-group {
  display: flex;

  > * {
    border-radius: 0;

    &:first-of-type {
      border-radius: $border-radius 0 0 $border-radius;
    }

    &:last-of-type {
      border-radius: 0 $border-radius $border-radius 0;
      flex-grow: 1;
    }
  }
}
