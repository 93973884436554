@import "mixins";
@import "variables/colors";

.notifications-wrapper {
  font-size: 1.25rem;
  position: fixed;
  top: 3rem;
  width: 100%;
  background-color: $white;
  left: 0;
  z-index: 1;

  .notifications-br {
    position: absolute;
    transform: translateY(100%);
  }

  .notification {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 1rem 2rem;
    transition: opacity 0.5s, height 0.5s, margin 0.5s, font-size 0.5s;
    cursor: pointer;

    .notification-dismiss {
      font-size: 2.5rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
      color: $black-disabled;
    }

    .notification-message {
      padding-top: 1rem;
      color: $black-medium-emphasis;
      line-height: rem(20px);
      font-size: rem(14px);
      letter-spacing: rem(0.25px);
    }

    &.notification-error {
      background-color: rgba(239, 66, 66, 0.05);
      border-left: rem(8px) solid $light-theme-alert-red;
      &::before {
        content: url(icons/error-icon.svg);
        width: 2rem;
        height: 2rem;
        position: absolute;
        left: 1.5rem;
        top: calc(50% - 1rem);
      }
    }

    &.notification-warning {
      background-color: rgba(255, 209, 37, 0.45);
    }

    &.notification-success {
      background-color: rgba(85, 204, 34, 0.05);
      border-left: rem(8px) solid $light-theme-alert-green;

      &::before {
        content: url(icons/checkmark-icon.svg);
        width: 2rem;
        height: 2rem;
        position: absolute;
        left: 1.5rem;
        top: calc(50% - 1rem);
      }
    }

    &.notification-visible {
      opacity: 1;
    }

    &.notification-hidden {
      opacity: 0;
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      font-size: 0;
    }

    & .notifications-title {
      line-height: rem(24px);
      font-size: rem(16px);
      letter-spacing: rem(0.15px);
    }
  }
}

@media (min-width: 768px) {
  .notifications-wrapper {
    width: initial;
    left: 50%;
    transform: translateX(-50%);
    min-width: 400px;

    .notification {
      padding-left: 5rem;
      padding-right: 3rem;
    }
  }
}
