@import "variables/colors";

.time-input-container {
  background-color: $white;
  border: 1px solid $table-cell-border;
  padding: 0.5rem;
  margin-left: 0.5rem;
  select {
    border: none;
    padding: 0 0.5rem;
    height: 100%;
    color: $black;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.time-label span {
  margin-left: 4px;
  &:last-of-type {
    margin-right: 4px;
  }
}
