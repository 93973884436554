@import "variables/colors";
@import "variables/configs";

.textarea {
  background-color: $white;
  border: 1px solid $input-border-color;
  padding: 8px 16px;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
  resize: none;

  &:focus {
    outline: none;
    border-color: $link_blue;
  }

  &--error {
    border-color: $risk_red;
  }

  &--disabled {
    background-color: $gray;
  }
}
