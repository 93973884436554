@import "variables/colors";

.sign-in-form {
  width: 328px;

  .form-field-title {
    color: $link-blue;
  }

  .button--primary {
    text-transform: uppercase;
  }

  .link {
    color: $link-blue;
  }

  .links-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -5px;
    margin-bottom: 66px;

    .link {
      max-width: 50%;
    }

    .link:nth-child(2) {
      text-align: right;
    }
  }
  &.reset-phonenumber-form {
    width: 370px;
    .select-option__menu {
      width: 370px;
    }
    .select-option__placeholder {
      max-width: 90px;
    }
  }
}

.user-tip {
  font-size: 14px;
  margin-bottom: 35px;
  margin-top: 6px;
  line-height: 1.33;

  &-header {
    color: $light_gray;
    font-size: 12px;
    margin-left: 15px;
    margin-top: 6px;
  }

  &-list {
    color: $light_gray;
    font-size: 12px;
    margin-left: 30px;
    margin-top: 6px;
  }
}
