.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text.h1,
  .text.span {
    width: 100%;
    text-align: center;
    display: block;
  }
}
