.loggedUser {
  display: flex;
  flex-direction: column;
  text-align: right;

  .text {
    font-size: 1rem;
    letter-spacing: 0.47px;
    line-height: 1.14;
  }
}
