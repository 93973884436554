.accordion-container {
  .accordion-item {
    line-height: 24px;
    letter-spacing: 0.15px;
    font-size: 16px;
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    width: auto;

    &.selected {
      background-color: #e3f2ff;
    }

    &:hover {
      background-color: #e3f2ff;
      cursor: pointer;
    }

    .content {
      max-height: 75px;
      overflow: hidden;
      transition: max-height 0.55s ease-in-out;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.25px;
      color: #454545;

      .name {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.15px;
        color: #000000;
      }

      &.expanded {
        max-height: 500px;
      }
    }

    &:not(:first-child) {
      border-top: solid 1px black;
    }
  }
}
