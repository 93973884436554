@import "variables/colors";

.error {
  font-size: 14px;
  color: $light-theme-alert-red;
}
.table {
  .error {
    line-height: 1rem;
    position: relative;
    top: 2px;
  }
}
