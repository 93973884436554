.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .label {
    margin-bottom: 2px;
  }

  .error {
    margin-top: 2px;
    margin-left: 15px;
  }
}
