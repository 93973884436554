@import "variables/colors";

.table.test-profiles-contacts-table,
.table.access-profiles-contacts-table {
  td {
    padding: 1.2rem 1.4rem;
  }
  .channels {
    width: 150px;
    padding: 1.2rem 1.4rem;
  }

  td {
    height: 32px;
    vertical-align: middle;
  }

  th,
  td {
    border: 1px solid $table-cell-border;
  }
}

.qr-code-details {
  tr {
    font-size: 14px;
  }

  th {
    background-color: $table-head-blue;
  }

  .title-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;

    .page-title {
      margin-top: 37px;
      margin-bottom: 40px;
    }
  }

  .header-add-button {
    clear: both;
    margin: 0;
  }

  .dates-container {
    width: 300px;

    .form-group {
      position: relative;
      margin-bottom: 0;
    }

    .separator {
      margin-right: 15px;
      margin-left: 15px;
    }

    .calendar {
      position: absolute;
      right: 5%;
      top: 30%;
      z-index: 1;
    }
    .select-option__control {
      min-width: 400px;
    }
    .date-picker {
      width: 95%;
      padding: 0 0.5rem;
      height: 46px;
      border-radius: 4px;
      font-size: 16px;
    }
  }
}
