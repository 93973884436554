@import "mixins";
@import "variables/colors";

.test-types-list {
  padding: 0 45px 20px 10px;
  display: flex;
  background-color: $light_blue;

  .header-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    span {
      display: inline-block;
      &.badge {
        background-color: $header-blue;
        border-radius: 50%;
        color: white;
        width: rem(14px);
        height: rem(14px);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(10px);
        font-weight: normal;
        margin-left: 20px;
      }
    }
  }

  img {
    margin-top: 50px;
  }
  .phone-image-1 {
    width: rem(218px);
    height: rem(336px);
  }

  .test-types-table-container {
    width: 100%;
    margin-left: 2px;

    .page-title {
      margin-left: 35px;
    }
  }
  .test-types-table.table {
    margin-right: 0;

    td {
      vertical-align: middle;
      padding: 0.8rem 1.4rem;
      height: 50px;
    }

    th,
    td {
      border: 1px solid $table-cell-border;
      border-left: 0;
    }
    td.actions-column,
    th.actions-column {
      border-right: 0;
    }

    input[type="checkbox"] {
      transform: scale(1.2);
      cursor: pointer;
      &:disabled {
        background-color: $secondary-gray;
      }
    }

    .actions-column {
      min-width: 100px;
    }

    .tr--clickable:hover {
      background-color: $light_blue;
    }

    .tr--selected {
      background-color: $tr-hover;
      &:hover {
        background-color: $tr-hover;
      }
    }
    .button--disabled {
      svg path {
        fill: $gray-disabled
      }
      .icon.duplicate:hover {
        svg path {
          fill: $gray-disabled
        }
      }
    }
  }
}
.page-title.test-type-configuration-header {
  margin: 45px 0 25px 0;
}
