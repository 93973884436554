@import "variables/colors";
@import "variables/font";

$headerHeight: 79px;

.mobileHide {
  display: inline;
}

.tm {
  font-size: 1.1rem;
  position: relative;
  bottom: 8px;
}

/* Smartphone Portrait and Landscape */
@media only screen and (max-device-width: 480px) {
  .mobileHide {
    display: none;
  }
}

.header {
  height: $headerHeight;
  box-shadow: 0 2px 0 0 rgba(172, 172, 172, 0.5);
  background-color: $header-blue;
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 22px;
  font-size: 1.272727rem;
  color: $white;

  &_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1;

    h2 {
      height: 100%;
      font-size: 1.43rem;
      font-weight: $semibold;
      margin-right: 10px;
      color: $white;
    }
  }

  &_right {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .h4 {
    margin: 0;
    color: $black;
  }

  .logo {
    margin-left: 20px;
  }
}

.mobileShow {
  display: none;
}

/* Smartphone Portrait and Landscape */
@media only screen and (max-device-width: 480px) {
  .mobileShow {
    display: inline;

    .header {
      padding: 20px;
      width: calc(100% - 40px);

      &_left {
        align-items: center;
        height: 100%;

        h2 {
          line-height: 19px;
          font-size: 14px;
          margin-right: 0;
          width: 80px;
        }
      }

      &_center {
        display: flex;
        align-items: center;

        .logo {
          margin-left: 0;
        }
      }

      &_right {
        display: flex;
      }
    }
  }
}

@media print {
  .header {
    display: none;
  }
}
