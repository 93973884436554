@import "variables/colors";

.home-test-profile {
  margin: 47px 25px 0 25px;

  .title-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 60px;
    .page-title {
      margin-top: 30px;
    }
  }
  .title-top.contact-title {
    margin-top: 20px;
  }

  .button-container {
    .button {
      margin-top: 0;
    }
    .button:first-of-type {
      margin-right: 0;
    }
  }

  .table {
    &.home-test-contacts {
      margin: 0;
    }
  }
  .page-title {
    margin: 0px 0px 14px;
  }
  div:not(.title-top) {
    .page-title {
      h1 {
        text-transform: capitalize;
      }
    }
  }

  th {
    background-color: $table-head-blue;
  }

  td {
    padding: 1.2rem 1.4rem;
  }
  .channels {
    width: 150px;
    padding: 1.2rem 1.4rem;
  }

  td {
    height: 32px;
    vertical-align: middle;
  }

  th,
  td {
    border: 1px solid $table-cell-border;
  }

  .enable-test-container {
    margin: 50px 0 0 10px;
    margin-bottom: 20vh;
    display: flex;
    p {
      font-size: 18px;
    }
  }
  .cell {
    overflow-wrap: anywhere;
  }
  .devider {
    margin: 0;
  }
}
