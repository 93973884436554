.dashboardLayout {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  &_right {
    flex-grow: 1;
    overflow-y: auto;
    max-height: calc(100vh - 79px);
  }
}

@media print {
  .dashboardLayout_right {
    overflow: visible;
  }
}
