@import "variables/colors";

.select-option__dropdown-indicator:after {
  content: "";
  border: solid $text_black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  margin-top: -2px;
}

span[aria-live] {
  display: none;
}

.custom-option {
  display: flex;
  justify-content: space-between;
  p:first-child {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
