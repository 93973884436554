@import "mixins";

.float-navbar {
  background: hsla(0, 0%, 69%, 0.59);
  position: fixed;
  top: 0;
  left: 0;
  animation: moveDown 0.5s ease-in-out;
  z-index: 1;
  width: 100%;
  margin-top: rem(79px);
  height: rem(79px);
  .header-add-button {
    margin-top: 15px;
  }
  .button {
    font-size: 1rem;
  }
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
    z-index: -1;
  }
  to {
    transform: translateY(0rem);
    z-index: 1;
  }
}
