@import "variables/colors";
@import "variables/font";
@import "mixins";

.table {
  margin: 0 25px;
  line-height: 1.27;

  table {
    @include size(100%);
    border-spacing: 0;
    border-collapse: collapse;

    th,
    td {
      margin: 0;
      padding: 5px 1.4rem;
      border: 1px solid $gray;
      text-align: left;
      overflow-wrap: anywhere;

      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }

      &.col-background-gray {
        background: $table_cell_gray;
        opacity: 0.7;
      }
      &.col-background-pale {
        background: $table_cell_pale;
      }
      &.min-width {
        min-width: 60px;
      }
      &.fix-width {
        width: 100px;
      }
    }

    .dropdown_toggle {
      margin-left: 10px;
    }

    .header_group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 32px;
      position: relative;

      &--aligned-top {
        vertical-align: top;
        position: relative;
      }

      .header-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-width: 70px;
        span {
          display: inline-block;
          &.badge {
            background-color: $header-blue;
            border-radius: 50%;
            color: white;
            width: rem(14px);
            height: rem(14px);
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: normal;
            font-size: rem(10px);
            flex-shrink: 0;
          }
        }
      }

      .header-with-icon {
        display: flex;
        width: 100%;
      }
    }

    .header_flex {
      padding: 0;
      .header_group {
        height: 100%;
        .header-content {
          min-width: unset;
        }
        >div {
          width: 100%;
          height: 100%;
        }
        .header-title {
          height: 100%;
        }
      }
    }

    .header_filterGroup {
      display: flex;
      justify-content: space-between;
      height: 100%;
      position: relative;
      padding-bottom: 5px;
      padding-top: 10px;

      > div {
        width: 100%;
      }

      .sub-header {
        color: $text_gray;
        font-weight: $normal;
      }

      .header-title {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-content: center;
      }

      .sorting-icons {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        .icon {
          align-self: center;
          svg {
            width: 10px;
            height: 10px;
            fill: $table-cell-border;
          }
        }

        .active.icon {
          svg path {
            fill: $primary-blue;
          }
        }
      }
    }

    td.multiline {
      white-space: pre-line;
    }

    td {
      p {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }

  .status-change {
    display: flex;
    align-items: center;

    .icon {
      width: 20px;
      margin-right: 15px;
      text-align: center;
    }
  }

  .id-status {
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 80%;
    .icon {
      width: 20px;
    }
    .system-id {
      width: 100%;
      text-align: right;
    }
  }

  .risk-level,
  .risk-level--aligned-top {
    display: flex;
    height: 100%;
    padding: 5px 10px;
    border-right: 4px solid;

    &.danger {
      border-color: $risk_red;
    }

    &.warning {
      border-color: $risk_orange;
    }

    &.medium {
      border-color: $risk_yellow;
    }

    &.low {
      border-color: $risk_green;
    }

    &.outcome--0 {
      border-color: $outcome-0;
    }

    &.outcome--1 {
      border-color: $outcome-1;
    }

    &.outcome--2 {
      border-color: $outcome-2;
    }

    &.outcome--3 {
      border-color: $outcome-3;
    }

    &.outcome--4 {
      border-color: $outcome-4;
    }

    &.outcome--5 {
      border-color: $outcome-5;
    }

    &.outcome--6 {
      border-color: $outcome-6;
    }

    &.outcome--7 {
      border-color: $outcome-7;
    }

    &.multiline {
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .risk-level {
    align-items: center;
  }

  .risk-level--aligned-top {
    align-items: top;
  }
  .padding-btm {
    padding-bottom: 10px;
  }

  .no-padding {
    padding: 0;
    height: 100%;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  .top-bar,
  .bottom-bar {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .global-filter-container {
      border-bottom: 1px solid $black;

      input {
        border: 0;
        outline: none;
      }
    }

    .pagination-container {
      margin-left: 40px;

      .hint {
        margin-right: 10px;
      }

      button {
        border: none;
        appearance: none;
        background: transparent;
        margin: 0 5px;
        font-weight: $bold;

        .icon.left {
          margin-left: -4px;
        }
        .icon.right {
          margin-right: -4px;
        }
      }
      button[disabled] {
        opacity: 0.5;
      }
    }
  }

  .page-size-options {
    font-size: 12px;
    p {
      margin-right: 10px;
    }
    .option {
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.active {
        color: $primary-blue;
        font-weight: bold;
      }
    }
  }

  .full-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .page-size-options {
      display: flex;
    }
  }

  .bottom-details {
    height: 60px;
    .page-size-options {
      float: left;
      margin-top: 20px;
      display: flex;
    }
  }

  .tr--clickable {
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    &:hover {
      background-color: transparentize($gray, 0.7);
    }
  }
  &:not(.organization-table):not(.test-types-table) {
    .tr--clickable:not(.tr--selected) {
      background-color: $tr-clickable;
    }
  }

  .tr--selected:not(.organization-table):hover {
    background-color: $table_row_selected;
  }

  .tr--selected {
    background-color: $table_row_selected;
  }

  .tr--sorted-desc {
    padding-top: 6px;
    border-bottom: 3px solid $table-sorted;
  }

  .tr--sorted-asc {
    padding-bottom: 6px;
    border-top: 3px solid $table-sorted;
  }
  .tr--aligned-top {
    padding-top: 10px;
    vertical-align: top;
  }

  textarea {
    vertical-align: middle;
    text-align: justify;
  }

  .text-filter {
    &_container {
      display: flex;
    }
    input {
      width: 100%;
      height: 10px;
      border-radius: 0;
      border: 1px solid $table-cell-border;
      padding: 10px 15px;
    }
  }

  .empty-table-info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    .info-content {
      height: 100px;
      max-width: 350px;
      display: flex;
      align-items: center;
      text-align: center;
    }
    hr {
      width: 100%;
      border: 1px solid $table-cell-border;
      margin-top: 30px;
    }
  }
}
