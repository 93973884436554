@import "variables/colors";

.contact-information {
  padding-top: 50px;
  flex-grow: 1;

  .header-add-button {
    margin-top: 0;
  }
  .page-title {
    margin-top: 0;
  }

  .table.patient-contacts-table {
    margin-top: 55px;
    td {
      padding: 1.2rem 1.4rem;
      height: 32px;
      vertical-align: middle;
    }
    th {
      background-color: $table-head-blue;
    }
    .channels {
      width: 150px;
      padding: 1.2rem 1.4rem;
    }

    th,
    td {
      border: 1px solid $table-cell-border;
    }
  }
}
