.infinity-integration {
  margin-top: calc(3rem + 11px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;

  .subtitle {
    margin-top: 5px;
    font-size: 12px;
    font-style: italic;
  }

  .enable-button {
    min-width: 156px;
    min-height: 51px;
    &.copy-button {
      min-width: 92px;
    }
  }
  .integration-button {
    display: flex;

    .integration-id {
      background-color: #ffffff;
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
