@import "variables/colors";
@import "variables/configs";
@import "variables/font";

.link {
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:visited,
  &:focus {
    color: $link_blue;
  }

  &.margin-top {
    display: inline-block;
    margin-top: 16px;
  }
  &.upper-case {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: $bold;
    letter-spacing: 1.8px;
  }
}
