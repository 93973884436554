@import "variables/colors";
@import "variables/configs";

.large-input {
  background-color: $light_blue;
  border-radius: 6px;
  border: 1px solid $input-border-color;
  padding: 16px 65px;
  transition: all 0.3s ease-in-out;
  text-align: center;

  &:focus {
    outline: none;
    border-color: $table-cell-border;
  }

  &--error {
    border-color: $risk_red;
  }
}
