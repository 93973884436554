@import "variables/colors";

.forgot-password-form {
  width: 315px;

  .button--primary {
    text-transform: uppercase;
  }

  .link.sign-in-link {
    color: $link-blue;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.8px;
  }
}
