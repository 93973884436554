@import "variables/colors";

.label {
  min-width: 280px;
  font-size: 14px;
  color: $text_black;
  margin-left: 15px;

  &--error {
    color: $light-theme-alert-red;
  }
}
