@import "variables/colors";

.patient-test-results {
  margin-left: 240px;

  .table.patient-test-results-table {
    margin-top: 25px;
    td {
      padding: 1.2rem 1.4rem;
      height: 32px;
      vertical-align: middle;
    }
    th {
      background-color: $table-head-blue;
    }

    th,
    td {
      border: 1px solid $table-cell-border;
    }
    .column-sample-id {
      max-width: 300px;
    }
    .spinner {
      width: 24px;
      height: 20px;
    }
  }
  .button {
    .button_text {
      .icon.print {
        svg {
          fill: #757575;
        }
      }
    }
  }
}
