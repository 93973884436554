@import "variables/colors";

.p {
  margin-bottom: 15px;
}
.text {
  .link {
    color: $link_blue;
  }
}
