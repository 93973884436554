@import "variables/colors";

.add-patient-form {
  .wrapper {
    width: 400px;
    .label {
      display: inline-block;
    }

    .form-field-title {
      margin: 0px;
    }

    .role-title {
      margin-top: 42px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .form-group {
      max-width: 480px;
      margin-bottom: 0px;
      margin-top: 21px;

      .error {
        margin-top: 2px;
        position: relative;
      }
      .select-option__menu {
        width: 400px;
      }
    }

    .reminder {
      color: #595959;
      margin-top: 5px;
      font-size: 14px;
    }

    .fields-required {
      margin: 10px 15px;
      font-size: 14px;
      letter-spacing: 0.47px;
    }
  }
}
