@import "variables/colors";
@import "variables/configs";
@import "variables/font";
@import "mixins";

@mixin largeSize {
  padding: 15px 22px;
  font-size: 1.142857rem;
}

@mixin smallSize {
  padding: 14px 22px;
  font-size: 1.142857rem;
}

@mixin standardSize {
  padding: 16px 24px;
  font-size: 1.142857rem;
}

@mixin fullWidth {
  width: 100%;
}

@mixin confirm {
  border-radius: 0;
  flex-basis: 0;
  flex-grow: 1;
  padding: 25px;
}

@mixin primary {
  color: $white;
  border: 1px solid $primary-blue;
  background-color: $primary-blue;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: transparentize($primary-blue, 0.2);
  }
}

@mixin primaryDisabled {
  color: $white;
  border: 1px solid $gray-disabled;
  background-color: $gray-disabled;
  cursor: default;

  &:hover {
    background-color: $gray-disabled, 0.5;
  }
}

@mixin primarySpinner {
  @include size(14px);
  border-color: rgba($white, 0.25);
  border-top-color: $white;
}

@mixin secondary {
  color: $white;
  border: 1px solid $secondary-gray;
  background-color: $secondary-gray;
  transition: all 0.3s ease-in-out;
  font-weight: normal;

  &:hover {
    background-color: transparentize($secondary-gray, 0.1);
  }
}

@mixin secondaryDisabled {
  color: $white;
  border: 1px solid $gray-disabled;
  background-color: $gray-disabled;
  cursor: default;

  &:hover {
    background-color: $gray-disabled, 0.5;
  }
}

@mixin secondarySpinner {
  @include size(14px);
  border-color: rgba($text_black, 0.25);
  border-top-color: $text_black;
}

@mixin outLinedDisabled {
  border: 1px solid $gray-disabled;
  color: $gray-disabled;
  cursor: default;
}

.button {
  border: none;
  cursor: pointer;
  border-radius: $border-radius;
  background-color: transparent;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.47px;
  font-size: rem(14px);

  &.margin-top {
    margin-top: 20px;
  }

  &.margin-top--medium {
    margin-top: 35px;
  }

  &.margin-top--large {
    margin-top: 60px;
  }

  &:focus {
    outline: none;
  }

  &--primary {
    @include standardSize;
    @include primary;

    &.button--disabled {
      @include primaryDisabled;
    }

    .spinner.spinner-circle {
      @include primarySpinner;
    }
  }

  &--primary-large {
    @include largeSize;
    @include primary;

    &.button--disabled {
      @include primaryDisabled;
    }

    .spinner.spinner-circle {
      @include primarySpinner;
    }
  }

  &--primary-small {
    @include smallSize;
    @include primary;

    &.button--disabled {
      @include primaryDisabled;
    }

    .spinner.spinner-circle {
      @include primarySpinner;
    }
  }

  &--primary-full-width {
    @include smallSize;
    @include primary;
    @include fullWidth;

    &.button--disabled {
      @include primaryDisabled;
    }

    .spinner.spinner-circle {
      @include primarySpinner;
    }
  }

  &--primary-confirm {
    @include largeSize;
    @include primary;
    @include confirm;
    border-bottom-right-radius: 4px;

    &.button--disabled {
      @include primaryDisabled;
    }

    .spinner.spinner-circle {
      @include primarySpinner;
    }
  }

  &--secondary {
    @include standardSize;
    @include secondary;

    &.button--disabled {
      @include secondaryDisabled;
    }

    .spinner.spinner-circle {
      @include secondarySpinner;
    }
  }

  &--secondary-large {
    @include largeSize;
    @include secondary;

    &.button--disabled {
      @include secondaryDisabled;
    }

    .spinner.spinner-circle {
      @include secondarySpinner;
    }
  }

  &--secondary-confirm {
    @include largeSize;
    @include secondary;
    @include confirm;
    border-bottom-left-radius: 4px;

    &.button--disabled {
      @include secondaryDisabled;
    }

    .spinner.spinner-circle {
      @include secondarySpinner;
    }
  }

  &--loading {
    .spinner {
      position: absolute;
      top: calc(50% - 4.5px);
      left: calc(50% - 4.5px);
    }

    .button_text {
      visibility: hidden;
    }
  }

  &--row-action {
    margin: 5px 15px 5px 5px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &--cancel-text {
    font-size: 12px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: $link_blue;
    margin-top: 14px;
  }

  &--text {
    font-size: 14px;
    letter-spacing: 1.25px;
    font-weight: 500;
    text-decoration: underline;
    text-transform: unset;
    color: $link_blue;
    margin-top: 14px;
    margin-left: 20px;
  }

  &--secondary-full-width {
    @include smallSize;
    @include secondary;
    @include fullWidth;

    &.button--disabled {
      @include secondaryDisabled;
    }

    .spinner.spinner-circle {
      @include secondarySpinner;
    }
  }

  &--outlined {
    @include fullWidth;
    @include smallSize;

    border: solid 1px #292929;
  }

  &--outlined-text {
   
    @include standardSize;
    
    color: $link_blue;
    border: 1px solid $primary-blue;
    background-color: $white;
    margin: 5px 15px 5px 5px;

    &.button--disabled {
      @include outLinedDisabled()
    }
   
  }
}
