@import "variables/font";
@import "variables/colors";

.navBar {
  width: 250px;
  border-right: 1px solid $gray;
  background-color: $light_blue;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 30px;
  flex-shrink: 0;

  &_item {
    color: $text_black;
    width: 100%;
    cursor: pointer;

    .p {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      font-size: 1.14rem;

      .icon {
        margin-right: 20px;
        width: 39px;
        display: flex;
        justify-content: center;
      }
    }

    &.active .p {
      font-weight: 600;
      color: $black;
    }

    &.active svg {
      path {
        fill: $icon-active;
      }
    }
  }

  button.navBar_item {
    display: inline-block;
    appearance: none;
    background: none;
    border: none;
    outline: none;
    text-align: left;
  }

  .logo svg {
    width: 25px;
  }

  &-patients {
    background-color: $light_blue;
  }
}

@media print {
  .navBar {
    display: none;
  }
}
