@import "variables/colors";

.reset-password-form {
  width: 315px;

  .form-field-title {
    color: $link-blue;
  }

  .button--primary {
    text-transform: uppercase;
  }

  .user-tip {
    list-style-position: inside;
  }
  .user-tip-list {
    white-space: pre-line;
    margin-left: 15px;
  }
}
