@import "mixins";
@import "variables/colors";

.results-container {
  display: flex;
  font-size: 14px;
  margin: 5px 0 0 0;
  background-color: $background-grey;
  padding-right: 45px;
  display: flex;
  box-sizing: border-box;
  border-top: 2px solid transparentize($color: $border-dark-grey, $amount: 0.5);

  .results-table {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: minmax(3rem, max-content);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin: 50px 0 14px 35px;
    border-right: none;
    border-left: none;
    width: 100%;
    height: auto;

    div.cell {
      padding: 1rem 0.5rem 1rem 1.5rem;
    }
    textarea {
      height: 3rem;
    }
    .row-1 {
      display: flex;
      justify-content: space-between;
      width: 100%;
      div:not(.wrapp-information) {
        border-right: 1px solid $table-row-border;
        border-bottom: 1px solid $table-row-border;
        border-top: 1px solid $table-row-border;
      }
      .cell {
        min-height: 3.25rem;
      }
      .cell:first-of-type {
        justify-content: space-between;

        .badge {
          position: static;
          margin-left: 5px;
        }
      }

      .cell:last-of-type {
        border-right: none;
      }

      input {
        margin-left: 1rem;
      }
    }
  }

  .phone-image-9 {
    margin: 50px 0 0 10px;
    width: rem(226px);
    height: rem(336px);
  }

  .icon {
    min-width: 1.75rem;
  }

  span.badge {
    display: inline-block;
    position: absolute;
    right: 5%;
    top: 35%;
    background-color: $header-blue;
    border-radius: 50%;
    color: white;
    width: rem(15px);
    height: rem(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(11px);
  }

  div.cell {
    padding: 0.5rem 0.5rem 1.25rem 1.5rem;

    width: 100%;
    line-height: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: top;
    position: relative;

    &:first-child {
      width: 65%;
    }

    .error {
      position: relative;
      top: 5px;
      line-height: 1rem;
    }
  }
}

.recipient-container {
  display: flex;
  font-size: 14px;
  margin: 45px 0 0 0;
  background-color: $background-grey;
  padding-right: 45px;
  display: flex;
  box-sizing: border-box;

  .description {
    display: block;
    margin-right: 12px;
  }

  .phone-image-9 {
    margin: 50px 0 0 10px;
    width: rem(226px);
    height: rem(336px);
  }

  .title-container {
    padding: 1rem 0 1rem 2.3rem;
  }

  .container-right {
    width: 100%;
  }

  .recipient-table {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin: 10px 35px 14px;
    border-right: none;
    border-left: none;

    .icon {
      min-width: 1.75rem;
      height: 21px;
      align-items: flex-start;
    }

    span.badge {
      display: inline-block;
      position: absolute;
      right: 20px;
      top: 12px;
      background-color: $header-blue;
      border-radius: 50%;
      color: white;
      width: rem(15px);
      height: rem(15px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(11px);
      min-width: rem(15px);
    }
    span:not(.badge):not(.day-hours-field):not(.icon) {
      margin-right: 20px;
    }

    div.cell {
      padding: 1rem 0.5rem 1rem 1.5rem;

      width: 100%;
      line-height: 1.5rem;
      display: flex;
      position: relative;

      &:first-child {
        width: 65%;
      }
    }
    div.textarea-container {
      width: calc(100% - 15px);
      border: none;
    }

    .row-1 {
      grid-area: 1 / 1 / 2 / 5;
      display: flex;
      justify-content: space-between;
      div:not(.wrapp-information, .textarea-container) {
        border-right: 1px solid $table-row-border;
        border-bottom: 1px solid $table-row-border;
        border-top: 1px solid $table-row-border;
      }
      .cell {
        min-height: 3.25rem;
      }
      .cell:first-of-type {
        justify-content: space-between;

        .badge {
          position: static;
        }
      }

      .cell:last-of-type {
        border-right: none;
        .error {
          position: relative;
          top: 0px;
        }
      }

      input {
        margin-left: 1rem;
      }
      textarea {
        height: 6.8rem;
      }
    }
  }

  .row-2 {
    grid-area: 2 / 1 / 3 / 5;
    display: flex;
    justify-content: space-between;

    div {
      border-right: 1px solid $table-row-border;
      min-height: 3.25rem;

      textarea {
        height: 6.8rem;
      }

      .error {
        position: relative;
        top: 0px;
      }

      &:first-of-type {
        border-top: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .row-3 {
    grid-area: 3 / 1 / 4 / 5;
    display: flex;
    justify-content: space-between;

    div {
      border-right: 1px solid $table-row-border;
      border-top: 1px solid $table-row-border;
      min-height: 3.25rem;

      textarea {
        height: 6.8rem;
      }

      .error {
        position: relative;
        top: 0px;
      }

      &:first-of-type {
        border-top: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .row-4 {
    grid-area: 4 / 1 / 5 / 5;
    display: flex;
    justify-content: space-between;

    div {
      border-right: 1px solid $table-row-border;
      border-top: 1px solid $table-row-border;
      min-height: 3.25rem;

      textarea {
        height: 6.8rem;
      }
      .error {
        position: relative;
        top: 0px;
      }

      &:first-of-type {
        border-top: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
  .row-5 {
    grid-area: 5 / 1 / 6 / 5;
    display: flex;
    justify-content: space-between;

    div {
      border-top: 1px solid $table-row-border;
      border-right: 1px solid $table-row-border;
      min-height: 3.25rem;

      textarea {
        height: 6.8rem;
      }
      .error {
        position: relative;
        top: 0px;
      }

      &:first-of-type {
        border-top: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .row-6 {
    grid-area: 6 / 1 / 7 / 5;
    display: flex;
    justify-content: space-between;

    div {
      border-top: 1px solid $table-row-border;
      border-right: 1px solid $table-row-border;
      min-height: 3.25rem;

      textarea {
        height: 6.8rem;
      }
      .error {
        position: relative;
        top: 0px;
      }

      &:first-of-type {
        border-top: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .row-8 {
    grid-area: 7 / 1 / 8 / 5;
    display: flex;
    justify-content: space-between;

    .cell {
      border-top: 1px solid $table-row-border;
      border-right: 1px solid $table-row-border;

      &:last-child {
        border-right: none;
      }
      &.cell:first-of-type {
        justify-content: space-between;

        .badge {
          position: static;
        }
      }
      span:nth-of-type(2),
      input {
        margin-left: 0;
      }
      .error {
        position: relative;
        top: 0px;
      }
    }
  }

  .row-9 {
    grid-area: 8 / 1 / 9 / 5;
    display: flex;
    justify-content: space-between;

    .cell {
      border-top: 1px solid $table-row-border;
      border-right: 1px solid $table-row-border;

      &:last-child {
        border-right: none;
      }
      &.cell:first-of-type {
        justify-content: space-between;
        border-top: none;

        .badge {
          position: static;
        }
      }
      span:nth-of-type(2),
      input {
        margin-left: 0;
      }
      .error {
        position: relative;
        top: 0px;
      }
    }
  }

  .row-10 {
    grid-area: 9 / 1 / 10 / 5;
    display: flex;
    justify-content: space-between;

    .cell {
      border-top: 1px solid $table-row-border;
      border-right: 1px solid $table-row-border;

      &:last-child {
        border-right: none;
      }
      &.cell:first-of-type {
        justify-content: space-between;
        border-top: none;

        .badge {
          position: static;
        }
      }
      span:nth-of-type(2),
      input {
        margin-left: 0;
      }
      .error {
        position: relative;
        top: 0px;
      }
    }
  }

  .row-11 {
    grid-area: 10 / 1 / 11 / 5;
    display: flex;
    justify-content: space-between;

    div {
      border-top: 1px solid $table-row-border;
      border-bottom: 1px solid $table-row-border;

      border-right: 1px solid $table-row-border;

      &:last-child {
        border-right: none;
      }
      &.cell:first-of-type {
        justify-content: space-between;

        .badge {
          position: static;
        }
      }

      &.cell:nth-of-type(2) {
        border-right: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .badge {
          position: static;
        }
      }
      .error {
        position: relative;
        top: 0px;
      }
    }
  }

  .cell {
    textarea {
      padding-right: 28px;
      margin-left: 0px;
    }
    .error {
      position: relative;
      top: 0px;
      left: 0px;
    }
  }

  .wrapp-information {
    border: solid 0px;
    width: 100%;
    span {
      position: unset;
    }
  }

  .day-hours-field {
    border: solid 0px;
    > div {
      border: none;
      display: flex;
      align-items: flex-start;
    }
    input {
      width: 100px;
      padding: 0.5rem;
      border: 1px solid $border-light-grey;
    }
  }
}
