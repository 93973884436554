@import "variables/colors";

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  perspective: 1000px;

  &_toggle {
    cursor: pointer;
  }

  &_items {
    display: none;
    position: absolute;
    flex-direction: column;
    background-color: $white;
    right: 0;
    top: 100%;
  }

  &--open {
    .dropdown {
      &_items {
        display: flex;
      }
    }
  }
}
