@import "variables/colors";

.switch-container {
  display: flex;
  margin-left: 10%;
  align-items: center;
  width: 10%;
  justify-content: space-between;

  .switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    min-width: 100px;
    height: 40px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
    margin: 0 1rem;
  }

  .switch-label .switch-button {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    transition: 0.3s;
    background: $header-blue;
    box-shadow: 0 0 3px 0 rgba(10, 10, 10, 0.29);
  }

  .switch-checkbox:checked + .switch-label .switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  .switch-checkbox:not(:checked) + .switch-label .switch-button {
    background: $white;
  }

  .switch-checkbox:disabled + .switch-label {
    background: rgba(128, 128, 128, 0.312);
  }
}

.switch-container.small-size {
  width: 34px;

  .switch-label .switch-button {
    top: -2px;
    left: -2px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }

  .switch-label {
    min-width: 34px;
    height: 14px;
  }

  .switch-checkbox:checked {
    + .switch-label .switch-button {
      left: calc(100% - 0px);
    }
    ~ .switch-label {
      background: rgba(0, 102, 204, 0.38) !important;
    }
  }

  .switch-label {
    margin: 0;
  }
}
