@import "variables/colors";

.search {
  &__form {
    padding: 25px 45px;

    .form-group {
      position: relative;
      .error {
        top: 100%;
        position: absolute;
      }
    }

    .dates-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 65px;

      .form-group {
        width: 200px;
      }

      .separator {
        width: 30px;
        align-content: center;
        display: flex;
        justify-content: center;
        margin-left: 15px;
      }

      .date-picker {
        background: #ffffff00;
        width: 100%;
      }
    }

    .select-option__control {
      min-width: 400px;
    }
    .date-picker,
    .date-picker--error {
      padding: 0 0.5rem;
      height: 46px;
      border-radius: 4px;
      border: 1px solid $text_black;
      font-size: 16px;
      text-transform: uppercase;
    }
    .date-picker--error {
      border: 1px solid $light-theme-alert-red;
      z-index: 0;
    }

    &-row-1 {
      display: flex;
      margin-bottom: 30px;
      .form-group:nth-child(2) {
        width: 462px;
        min-width: 462px;
      }
    }
    &-row-2 {
      display: flex;
      .form-group {
        width: 462px;
        margin-right: 48px;
        min-width: 462px;
      }
    }
  }
  .buttons-container {
    padding: 0 45px;
    button {
      min-width: 160px;
    }
    .button-cancel {
      margin-left: 20px;
    }
  }
}
