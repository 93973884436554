@import "variables/colors";

.data-elements-select-testtype {
  margin-left: 45px;
  margin-top: 40px;
  margin-bottom: 300px;
  width: 350px;
}

.data-elements-devider {
  margin: 1rem 20px 1rem;
  padding-bottom: 4rem;
  border-top: 2px solid $header-blue;
  opacity: 0.6;
}

.data-elements-page-title {
  padding-bottom: 3rem;
}
