@import "mixins";
@import "variables/colors";

.cookie-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .cookie-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: $white;
    box-sizing: border-box;
    width: 95%;
    height: 30%;
    border-radius: 4px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    font-size: rem(14px);
    line-height: rem(16px);
    letter-spacing: rem(0.47px);

    p {
      margin-top: -1rem;
    }

    .link {
      color: $link_blue;
      text-decoration: underline;
    }

    .button {
      align-self: flex-end;
    }
  }
}
