@import "variables/colors";

.sign-in-form {
  width: 280px;

  .link.sign-in-link {
    color: $link-blue;
    display: block;
    text-align: center;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
  }
}
