@import "mixins";
@import "variables/colors";

.recipient-container {
  display: flex;
  font-size: 14px;
  padding: 1rem 0;
  margin: 0 0 14px;
  background-color: $background-grey;
  display: flex;
  box-sizing: border-box;

  .phone-image-8 {
    margin: 50px 0 0 10px;
    width: rem(226px);
    height: rem(336px);
  }

  .container-right {
    width: 100%;
  }

  thead {
    display: none;
  }

  .days-hours-input {
    display: flex;
    align-items: center;
    span {
      width: 75px;
      margin-right: 1rem;
    }
    .icon {
      margin-right: 1rem;
      width: 21px;
      height: 21px;
    }
  }

  .secondColumn {
    width: 30%;
    vertical-align: top;
  }
  .thirdColumn {
    vertical-align: top;

    .days-hours-input {
      margin-left: 25px;
    }
  }

  .icon {
    margin-right: 1rem;
  }

  .title-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    padding-right: 45px;
  }

  thead {
    background-color: $table-head-grey;
  }

  .test-recipients-table {
    th,
    td {
      border: 1px solid $table-cell-border;
      padding: 1.2rem 0.4rem 1.9rem;
      height: 3.25rem;
    }
    tbody tr:nth-of-type(8),
    tr:nth-of-type(9),
    tr:nth-of-type(10) {
      th,
      td {
        padding: 1.2rem 0.4rem 1.2rem;
        height: unset;
      }
    }
  }

  .time-row {
    width: 60%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-content-row {
    display: flex;
    justify-content: space-between;
    height: 100%;
    font-weight: bold;
    span {
      display: inline-block;
      &.badge {
        font-size: 11px;
        font-weight: normal;
        background-color: $header-blue;
        border-radius: 50%;
        color: white;
        min-width: 1rem;
        min-height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
      }
    }
  }

  textarea {
    vertical-align: middle;
    text-align: justify;
    height: 6.8rem;
  }

  .textarea-disabled {
    display: flex;
    height: 3rem;
    div {
      width: 100%;
    }
  }

  .cell-icon {
    display: flex;
  }

  th,
  td {
    border: 1px solid $table-cell-border;
  }

  tbody tr:first-of-type {
    td:nth-of-type(1) {
      width: 18%;
    }
  }

  tbody tr:nth-of-type(3),
  tr:nth-of-type(4) {
    td:nth-of-type(1) {
      visibility: hidden;
      border-bottom: 1px solid $table-cell-border;
      border-top: none;
      border-bottom: none;
    }
  }

  tbody tr:nth-of-type(2),
  tr:nth-of-type(3),
  tr:nth-of-type(4),
  tr:nth-of-type(5),
  tr:nth-of-type(6),
  tr:nth-of-type(7),
  tr:nth-of-type(9),
  tr:nth-of-type(10) {
    td:nth-of-type(1) {
      border-bottom: none;
      border-top: none;
    }
  }

  tr:nth-of-type(8) {
    td:nth-of-type(1) {
      border-bottom: none;
    }
  }

  tbody tr:last-of-type {
    td:nth-of-type(2) {
      border-right: none;
    }
    td:nth-of-type(3) {
      border-left: none;
    }
  }
  .error {
    line-height: 1;
    position: absolute;
    top: 94px;
    padding: 0px;
  }
  &.top-container {
    border-top: 2px solid
      transparentize($color: $border-dark-grey, $amount: 0.5);
  }
}
