@import "mixins";
@import "variables/colors";

.provider-container {
  display: flex;
  font-size: 14px;
  margin: 45px 0 0 0;
  background-color: $background-grey;
  padding-right: 45px;
  display: flex;
  box-sizing: border-box;
  border-top: 2px solid transparentize($color: $border-dark-grey, $amount: 0.5);

  .phone-image-8 {
    margin: 50px 0 0 10px;
    width: rem(226px);
    height: rem(336px);
  }

  .container-right {
    width: 100%;
  }

  thead {
    display: none;
  }

  .provider-details-col1 {
    width: 18%;
  }
  .provider-details-col {
    width: 30%;
  }

  .test-provider-table {
    margin-right: 0;
    th,
    td {
      border: 1px solid $table-cell-border;
      padding: 1.2rem 0.4rem 1.9rem;
      height: 3.25rem;
    }
    .error {
      line-height: 1;
      position: absolute;
      top: 40px;
      padding: 0px;
    }
  }
  .title-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }

  thead {
    background-color: $table-head-grey;
  }

  .time-row {
    width: 60%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-content {
    &,
    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        display: inline-block;
        &.badge {
          font-size: 10px;
          font-weight: normal;
          background-color: $header-blue;
          border-radius: 50%;
          color: white;
          min-width: 1rem;
          min-height: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .textarea-disabled {
    display: flex;
    height: 3rem;
  }

  th,
  td {
    border: 1px solid $table-cell-border;
  }

  tbody tr:first-of-type {
    td:nth-of-type(2) {
      border-right: 1px solid $table-cell-border;
    }
    td:nth-of-type(3),
    td:nth-of-type(4) {
      border-left: none;
      border-right: none;
    }
  }

  tbody tr:nth-of-type(3),
  tr:nth-of-type(4) {
    td:nth-of-type(1) {
      visibility: hidden;
      border-bottom: 1px solid $table-cell-border;
      border-top: none;
      border-bottom: none;
    }
  }

  tbody tr:nth-of-type(2) {
    td:nth-of-type(1) {
      border-bottom: none;
    }
  }

  .time-input {
    padding-bottom: 5px;
    span {
      margin-left: 4px;
      &:last-of-type {
        margin-right: 20px;
      }
    }
  }

  .time-cell {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    position: relative;
    .error {
      top: 100%;
    }
  }
}
