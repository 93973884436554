@import "variables/colors";
@import "variables/configs";

.wrapper {
  background-color: $white;
  border-radius: $border-radius;
  padding: 30px 35px;
  font-size: 16px;
}

.pass-rule-not-matched {
  color: $risk_red;
}
